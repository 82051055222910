.wrapper {
  box-sizing: border-box;
  background-color: var(--dig-color__background__subtle);
  justify-content: space-between;
  cursor: auto;
  padding-left: var(--spacing__unit--2);
  padding-right: var(--spacing__unit--2);
}

.wrapper:hover {
  background-color: var(--dig-color__background__subtle);
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.actionGroup {
  display: flex;
  gap: var(--dig-spacing__dimension__8);
}

.actionGroup:after {
  content: '';
  border-right: 1px solid var(--dig-color__border__subtle);
  margin-right: var(--dig-spacing__dimension__8);
}

.augustActionGroup:after {
  margin: 0;
  border: none;
}

.actionGroup:last-child:after {
  display: none;
}

.showActionGroup {
  opacity: 1;
  transition: opacity 1s ease-in;
  pointer-events: auto;
}

.hideActionGroup {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  pointer-events: none;
}

.searchLabel {
  border-radius: 100%;
}

.searchLabelWrapper {
  line-height: 0;
}

.augustWrapper {
  padding: 0 var(--dig-spacing__micro__medium);
  background-color: transparent;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
}
