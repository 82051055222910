.quickLinksContainer {
  margin-bottom: 4px;
}

.quickLinksTitle {
  line-height: 30px;
  margin-left: 12px;
}

.flexContainer {
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
}

.button {
  border-color: var(--dig-color__border__subtle);
}

.placeHolder {
  width: 40px;
  height: 40px;
}

.faviconImg {
  height: 20px;
  width: 20px;
}

.menuButton {
  display: none;
  height: 20px;
  cursor: pointer;
}

.menuButtonShow {
  display: block;
}

.menuContainer:hover .menuButton,
.iconButton:focus .menuButton {
  display: block;
}

.menuButtonContainer {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton {
  height: 18px;
}

@media (max-width: 863px) {
  .flexContainer {
    justify-content: left;
    gap: 16px;
  }
}
