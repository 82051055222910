.container.isNarrow {
  --multi-answer-card__max-height: calc(100vh - 160px);
  --multi-answer-card__desktop__max-height: calc(100vh - 215px);
}

@media screen and (min-width: 889px) {
  .container.isNarrow {
    overflow: hidden;
    max-height: var(--multi-answer-card__max-height) !important;
    overflow-y: auto;
  }

  .container.isNarrow.isDesktop {
    max-height: var(--multi-answer-card__desktop__max-height) !important;
  }
}

.container.isNarrow.isMobile {
  margin: 0 var(--dig-spacing__dimension__16);
}

.container.isNarrow .badge {
  margin-left: var(--dig-spacing__dimension__12);
}

.divider {
  height: 1px;
  background-color: var(--dig-color__border__subtle);
  margin: var(--dig-spacing__dimension__12) 0;
}

.cardContent {
  animation-name: popUp, fadeIn;
  animation-duration: 500ms, 250ms;
  animation-timing-function: cubic-bezier(0.01, 0, 0, 1), linear;
}

.cardContent ul {
  padding-left: 12px;
  margin-top: 0;
}

.cardContent ul li {
  margin: 4px 0;
}

.cardContent ul li p {
  margin: 0;
}

.followUpContainer {
  display: flex;
  justify-content: flex-end;
  margin: var(--dig-spacing__dimension__16) 0;
}

.experimentalNoticeText {
  display: block;
  margin: 0;
  padding: 0;
}

.container.isWide .content {
  position: relative;
  overflow: hidden;
  transition: max-height 750ms cubic-bezier(0.17, 0, 0.09, 1);
}

.container.isWide .content.isTruncated {
  max-height: 162px;
}

.container.isWide .content.isLoading {
  /* fix height of content while loading */
  height: 162px;
}

.container.isWide .content.isTruncated::after {
  /* Add a gradient mask to fade out the text at the bottom */
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  pointer-events: none;
}

.container.isWide.isDarkMode .content.isTruncated::after {
  background: linear-gradient(180deg, #1a191800 0%, #1a1918ff 100%);
}

.container.isWide {
  display: flex;
  gap: 60px;
  max-width: min(1100px, 100%);
  width: 100%;
  min-width: 0;
  flex-direction: row;
  padding-bottom: 11px;
  border-bottom: 1px solid var(--dig-color__border__subtle);
}

.container.isWide .mainContent {
  min-width: 0;
  width: 100%;
}

.container.isWide .titleContainer {
  color: var(--dig-color__text__subtle);
  margin-bottom: 8px;
}

.container.isWide .titleIcon {
  height: 24px;
  width: 24px;
}

.container.isWide .sidebarContent {
  min-width: 280px;
  max-width: 340px;
  width: 100%;
  flex-shrink: 1;
}

.seeMoreButton:global(.dig-Button) {
  border-radius: var(--dig-radius__large);
}

.container.isWide .experimentalNoticeText {
  margin-top: 11px;
  text-align: center;
}

.connectorIcons {
  display: inline-flex;
  flex-direction: row;
  gap: 0px;
}

.connectorIcon {
  padding: 4px;
}
