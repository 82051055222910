.contentEditable {
  width: 100%;
  max-width: 700px;
  padding-top: var(--dig-spacing__macro__xlarge);
  padding-bottom: 96px;
  box-sizing: border-box;
  color: var(--dig-color__text__base);
  font-family: var(--type__body__standard--fontfamily);
  font-size: var(--type__body__standard--fontsize);
  font-weight: var(--type__body__standard--fontweight);
  line-height: var(--type__body__standard--lineheight);
  margin: 0 auto;
  cursor: text;
}

.contentEditable:global(.focus-visible) {
  outline: none !important;
  box-shadow: none;
  border: none !important;
}

.contentEditable p {
  margin-top: 0;
  margin-bottom: 0;
}

.contentEditable ul,
.contentEditable ol {
  padding-inline-start: 14px;
}

.placeholder h1,
.contentEditable h1 {
  font-family: var(--dig-type__family__text);
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
}

.placeholder h2,
.contentEditable h2 {
  font-family: var(--dig-type__family__text);
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
}

.placeholder h3,
.contentEditable h3 {
  font-family: var(--dig-type__family__text);
  font-size: var (--dig-type__size__text__medium);
  font-weight: var(--dig-type__weight__strong);
  line-height: var(--dig-type__lineheight__label__large);
}

.placeholder h4,
.contentEditable h4,
.placeholder h5,
.contentEditable h5,
.placeholder h6,
.contentEditable h6 {
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-type__size__text__medium);
  font-weight: var(--dig-type__weight__strong);
  line-height: var(--dig-type__lineheight__title__large);
}

.placeholder p,
.contentEditable p {
  font-family: var(--dig-type__family__text);
  font-size: var(--type__body__large--fontsize);
  font-weight: var(--type__body__standard--fontweight);
  line-height: var(--type__body__standard--lineheight);
}

.placeholder ul,
.contentEditable ul,
.placeholder ol,
.contentEditable ol {
  padding-top: var(--dig-spacing__micro__small);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 24px;
  line-height: 1.6em;
  margin-top: 0.5em;
  margin-bottom: 1.2em;
  padding-left: 0;
}

.placeholder li,
.contentEditable li {
  padding-left: 0px;
  padding-bottom: 8px;
}

/* Remove margin-top if doc started with a header element */

.placeholder h1:first-child,
.contentEditable h1:first-child,
.placeholder h2:first-child,
.contentEditable h2:first-child,
.placeholder h2:first-child,
.contentEditable h3:first-child {
  margin-top: 0;
}
