.textFieldContainer :global(.dig-TextInputContainer) {
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__large);
}

.textFieldContainer
  :global(.dig-TextInputContainer):has(input[aria-invalid='true']) {
  border: 1px solid var(--dig-color__alert__base);
}

.textField {
  height: 40px !important;
}

.menuButtonInput {
  margin-top: var(--dig-spacing__dimension__20);
}

.avatar {
  width: var(--dig-spacing__dimension__16);
  height: var(--dig-spacing__dimension__16);
  margin-right: var(--dig-spacing__dimension__4);
}

.icon {
  padding-left: 0;
  width: 0;
  transition: width var(--duration__micro-fast) var(--easing__linear);
}

.icon.focused {
  padding-left: 8px;
  width: 32px;
}
