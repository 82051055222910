.truncatedCommentContent {
  --line-clamp-count: 3;

  display: -webkit-box;
  line-clamp: var(--line-clamp-count);
  overflow: hidden;
  padding-bottom: var(--spacing__unit--0_5);
  user-select: text;
  -webkit-box-orient: vertical;
  -webkit-display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp-count);
  white-space: pre-wrap;
}

.truncatedCommentContent.truncated::before {
  content: ' ';
  float: right;
  /* 
     *  Calculate the vertical position for the inline show more button. This puts it
     *  at the bottom of the last line of text and adds an extra pixel to account for the
     *  difference between the button and the plain text in the container.
     */
  height: calc((var(--line-clamp-count) - 1) * 1lh + 1px);
}

.truncatedCommentContent.expanded {
  line-clamp: unset;
  overflow: visible;
  -webkit-line-clamp: unset;
}

.showMoreButton {
  clear: both;
  float: right;
  margin-left: var(--spacing__unit--1_5);
}

.showLessButton {
  display: block;
  float: right;
}

/* Remove the standard spacing element from the metadata-adjacent show less button */
.showLessButton::before {
  display: none;
}

/* Don't show the safari-only button when not on Safari */
.safariShowMoreButton {
  display: none;
}

.commentMetadata {
  align-items: center;
  display: flex;
}

.commentMetadata > * + *::before {
  color: var(--dig-color__text__subtle);
  content: '•';
  margin: 0 var(--spacing__unit--1);
}

.commentInlineButton {
  display: inline-flex;
}

/**
 * Safari does not support floating elements in -webkit-box containers, which is required for line-clamping.
 * This allows us to work around that with a less optimal solution for Safari.
 * This @supports block was found in https://wojtek.im/journal/targeting-safari-with-css-media-query
 */
@supports (hanging-punctuation: first) and (font: -apple-system-body) and
  (-webkit-appearance: none) {
  /* Don't render the pseudo element responsible for setting button line height */
  .truncatedCommentContent.truncated::before {
    display: none;
  }

  /* Don't render the default show more button */
  .showMoreButton {
    display: none;
  }

  /* Render the Safari-specific show more button */
  .safariShowMoreButton {
    display: block;
    float: right;
  }

  /* Remove the standard spacing element from the Safari-specific show more button */
  .safariShowMoreButton::before {
    display: none;
  }
}
