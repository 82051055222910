.header {
  text-align: center;
  margin-bottom: var(--spacing__unit--2);
}

.subHeader {
  font-family: 'Atlas Grotesk Web';
  text-align: center;
}

.actionButton {
  border-radius: var(
    --dig-radius__xlarge
  ) !important /* important is required in order to override dig overrides */;
}

.actionButtonLabel {
  padding-left: var(--spacing__unit--2);
  padding-right: var(--spacing__unit--2);
}

.spotImage {
  height: 264px;
  width: 264px;
}
