.selfServeOnboarding {
  height: 100%;
}

.dashHeader {
  flex: 0 0 96px;
}

.contentHeader {
  margin-top: 65px;
  max-width: 800px;
  margin-bottom: var(--spacing__unit--3);
}

.contentHeader h2 {
  margin-bottom: 0;
}

.subHeader {
  max-width: 400px;
  margin-bottom: var(--spacing__unit--2);
}

.atlasGrotesk {
  font-family: 'Atlas Grotesk Web';
}

/* If the height of the bottomContainer changes, update the snackbarMarginBottom value within OnboardingConnectorsV2 */
.bottomContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dig-color__background__base);
}

.textCentered {
  text-align: center;
}

.logo,
.spacer {
  flex: 1;
}
