.EditorContainer {
  position: relative;
  flex: 1 1 auto;
  height: 100%;
}

.placeholderWrapper {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  color: var(--dig-color__disabled__base);
  user-select: none;
  pointer-events: none;
  display: flex;
  justify-content: center;
}

.placeholder {
  max-width: 700px;
  padding: var(--dig-spacing__macro__xlarge);
}

.contentEditableScroller {
  flex: 1 1 260px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-left: var(--dig-spacing__macro__xlarge);
  padding-right: var(--dig-spacing__macro__xlarge);
  box-sizing: border-box;
}

.spacer {
  flex: 1 1 0;
}

.divider {
  width: 1px;
  background-color: var(--dig-color__border__subtle);
  margin: 0 16px;
  height: 32px;
}

.editorExportButton {
  align-self: center;
  margin-right: 8px;
}

.WaitingForResponseIndicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: color-mix(
    in srgb,
    var(--dig-color__background__base) 60%,
    transparent
  );
}

.templatesButton {
  flex: 0 0 auto;
}

.templatesIcon {
  flex: 0 0 auto;
}
