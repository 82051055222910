.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.item {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing__unit--1_5);
}

.details a {
  display: table;
}

.details a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 980px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}
