.SourcesFromSettingsTwoContent {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  height: 694px;
}

.SourcesFromSettingsTwoSplitContent {
  display: flex;
  flex: 1;
}

.SourcesFromSettingsTwoHeader {
  display: flex;
  border-bottom: 1px solid var(--dig-color__border__subtle);
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__macro__medium)
    var(--dig-spacing__micro__small) var(--dig-spacing__macro__small);
  justify-content: flex-start;
  align-items: center;
  gap: var(--dig-spacing__micro__small);
  align-self: stretch;
}

.SourcesFromSettingsTwoLoadingSpinner {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  position: absolute;
}

.SourcesFromSettingsTwoLoadingOverlay {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  background: var(--dig-color__background__base);
  z-index: 10;
  position: absolute;
}

.SourcesFromSettingsTwoFooter {
  bottom: 0;
  border-top: 1px solid var(--dig-color__border__subtle);
  background: var(--dig-color__background__base);
  padding: var(--dig-spacing__micro__small);
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: -webkit-fill-available;
  border-radius: 0 0 var(--dig-radius__large) 0;
}

.SourcesFromSettingsRight {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px 0 var(--dig-color__shadow__elevated);
  border-left: 1px solid var(--dig-color__border__subtle);
  flex: 4;
}

.SourcesFromSettingsConversationPane {
  flex: 1;
}

.SourcesFromSettingsTwoMobile {
  height: 100vh;
}

.SourcesFromSettingsTwoMobileContent {
  flex: 1;
  position: relative;
  overflow: auto;
}

.SourcesFromSettingsTwoMobileToggle {
  position: fixed;
  top: 27px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: var(--dig-color__background__elevated);
  box-shadow: 0 2px 4px var(--dig-color__shadow__base);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
}

.SourcesFromSettingsTwoMobileToggle:hover {
  background: var(--dig-color__background__hover);
}

.LoadingMessageRow {
  display: flex;
  gap: 12px;
  width: max-content;
  color: var(--dig-color__text__base);
}

.ConversationMessageRowMessageActionIconContainer {
  background: var(--dig-color__background__base);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid var(--dig-color__border__subtle);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.ConversationMessageRowMessageActionIcon {
  color: var(--dig-color__text__subtle);
}

.TemplatePreviewLayout {
  display: flex;
  width: 100%;
  height: 100%;
}

.TemplatePreviewLayout .SourcesFromSettingsRight {
  flex: 2;
  width: 65%;
  min-width: 65%;
  position: relative;
  overflow: auto;
  height: 602px;
}
