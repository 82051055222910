.ConversationMessageLoadingText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5px;
  font-size: var(--dig-type__size__text__large);
  margin-top: 4px;
}

.loader {
  width: 2px;
  height: 2px;
  background: var(--dig-color__text__base);
  opacity: 1;
  animation: bouncing-loader 0.7s infinite alternate;
  margin-top: 2px;
}

.loader:nth-child(4) {
  animation-delay: 0.2s;
}

.loader:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.5;
    transform: translateY(2px);
  }
}
