.jtbdContainer {
  gap: var(--dig-spacing__dimension__16);
  justify-content: center;
}

.jtbdOption {
  box-sizing: border-box;
  flex: 1;
  background: var(--dig-color__background__base);
  border: 1px solid var(--dig-color__border__subtle);
  box-shadow: var(--boxshadow__elevation1);
  border-radius: var(--dig-spacing__micro__large);
  padding: var(--dig-spacing__dimension__16) var(--dig-spacing__dimension__32);
  height: 424px;
  cursor: pointer;
}

.jtbdContainerActive .jtbdOption {
  box-shadow: none;
}

/* Apply grayscale only when at least one item is selected */
.jtbdContainerActive .jtbdOption:not(.selected) img {
  filter: grayscale(1);
}

/* Ensure selected items always stay in color */
.jtbdContainerActive .jtbdOption.selected img {
  filter: grayscale(0);
}

.jtbdOption:hover,
.jtbdOption:active {
  box-shadow: var(--boxshadow__elevation2);
}

.jtbdContainerActive .jtbdOption.selected {
  box-shadow: var(--boxshadow__elevation1);
  outline: 2px solid var(--dig-color__highlight__purple--bolder) !important;
  outline-offset: -1px;
}

.jtbdImgContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.jtbdImgContainer img {
  max-width: 100%;
}

.jtbdDescription {
  color: var(--dig-color__text__subtle);
}

@media (max-width: 599px) {
  .jtbdContainer {
    flex-direction: column;
  }
  .jtbdOption {
    width: 100%;
  }
}
