/* Move Card level top/bottom padding to Card.Contents
 * to allow for full card hoverability */

.card {
  --card-background-link-hover: var(--dig-color__opacity__surface);
  --card-background-link-active: var(--dig-color__opacity__surface--state-1);
}

/* Note: do not remove the extra .card class in the selector,
   it is needed to override specificity in the DCL card component due to a bug in webpack that reorders the CSS in the final bundle */

.card.card {
  padding: 0;
}

.card.hasHeader {
  padding-top: var(--dig-spacing__micro__xlarge);
}

.cardContents {
  padding: var(--dig-spacing__micro__xlarge) 0;
}

.cardContents.cardBreakoutXSmall {
  padding: var(--dig-spacing__micro__xlarge) 0;
}

.cardContents.cardBreakoutSmall {
  padding: var(--dig-spacing__micro__large) 0;
}

.cardContents.cardBreakoutStandard {
  padding: var(--dig-spacing__micro__medium) 0;
}

.cardContents.cardBreakoutLarge {
  padding: var(--dig-spacing__micro__small) 0;
}

.cardContents.cardBreakoutXLarge {
  padding: var(--dig-spacing__micro__xsmall) 0;
}

.cardLink {
  text-decoration: none;
}

.cardLink:focus {
  outline: 0;
}

.card:has(.cardLink:focus-visible) {
  box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  outline: 0;
}

.card.isLink:has(.cardLink:focus-visible)::before,
.card.isLink:has(.cardLink:focus-within)::before {
  background-color: var(--card-background-link-hover);
}

.card.isLink:has(.cardLink:focus):active::before {
  background-color: var(--card-background-link-active);
}

.card * {
  --dash-radius__component: var(--dig-radius__small);
}
