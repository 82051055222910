.inviteFormBody {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__micro__medium);
}

.optionalMessageLabel {
  margin-top: var(--dig-spacing__micro__large);
  color: var(--dig-color__text__subtle);
  line-height: var(--dig-type__lineheight__label__xsmall);
  font-size: var(--dig-type__size__text__xsmall);
}

.optionalMessageTextArea {
  border-color: var(--dig-color__border__subtle);
  padding-top: var(--dig-spacing__micro__xsmall);
  padding-bottom: var(--dig-spacing__micro__xsmall);
  padding-right: var(--dig-spacing__micro__small);
  padding-left: var(--dig-spacing__micro__small);
}

.inviteFormRow {
  margin-bottom: var(--dig-spacing__micro__small);
}

.availableLicensesLabel {
  color: var(--dig-color__text__subtle);
}

.addTypeaheadButton {
  transform: translateX(-12px);
}
