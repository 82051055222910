.TemplatesListContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.TemplatesRows {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__macro__small);
  height: 618px;
}

.TemplatesRowsHeader {
  margin-left: var(--dig-spacing__micro__medium);
  margin-bottom: var(--dig-spacing__micro__xsmall);
}

.TemplatesRowsHeaderButtons {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--dig-spacing__micro__medium);
}

.TemplatesRow {
  align-items: center;
  border-radius: var(--dig-radius__small);
  cursor: pointer;
  display: flex;
  margin-bottom: var(--dig-spacing__micro__xsmall);
  padding: var(--dig-spacing__micro__small);
  transition: background-color 0.2s ease;
}

.TemplatesInputContainer {
  border: 1px solid var(--dig-color__border__subtle);
  margin-bottom: var(--dig-spacing__micro__medium);
}

.TemplatesSearchInputIcon {
  color: var(--dig-color__text__subtle);
  margin-right: var(--dig-spacing__micro__xsmall);
}

.TemplateListItem {
  height: 40px;
  border-radius: var(--dig-radius__small);
  padding: 0 var(--dig-spacing__micro__medium);
}

.TemplateListItem:hover .TemplatesRowIcon {
  opacity: 1;
}

.TemplateListItemSelected {
  background: var(--dig-color__opacity__surface);
  border-radius: var(--dig-radius__small);
}

.TemplatesRowHeader {
  align-items: center;
  display: flex;
  flex: 1;
  min-width: 0;
}

.TemplatesRowIcon {
  align-items: center;
  color: var(--dig-color__text__secondary);
  display: flex;
  flex-shrink: 0;
  margin-left: var(--dig-spacing__micro__xsmall);
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.TemplatesRowIcon:hover {
  color: var(--dig-color__text__primary);
}

.TemplatesRowTitle {
  flex: 1;
  margin-right: var(--dig-spacing__micro__xsmall);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TemplatesRowDescription {
  color: var(--dig-color__text__secondary);
  font-size: var(--dig-font-size__small);
  margin-top: var(--dig-spacing__micro__xsmall);
}
