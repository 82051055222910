.modal :global(.dig-Modal-content) {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.modalHeader {
  margin-bottom: var(--spacing__unit--2);
}

.modalBody {
  flex: 1 1 auto;
  height: 720px;
  overflow-y: auto;
  margin-bottom: var(--spacing__unit--2);
}

.modalBody.mobile {
  height: auto;
}

.header {
  position: relative;
}

.filterInput {
  flex: 0 0 360px;
  width: auto;
}

.filterInput :global(.dig-TextInputContainer--standard) {
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: 100px;
  height: 40px;
}

.filterInput :global(.dig-TextInputAccessory) {
  /* The search icon needs to shift down because we increased the height of the input */
  margin-top: var(--dig-spacing__dimension__2);
  margin-right: var(--dig-spacing__dimension__12);
}

.closeButton {
  position: absolute;
  right: 0;
}

.iconContainer {
  width: 128px;
  height: 128px;
}

.noResultsTitle {
  color: var(--dig-color__text__subtle);
}
