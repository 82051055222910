body {
  background-color: var(--dig-color__background__base);
  margin: 0;
  overscroll-behavior: none;
}

/* 
  .meticulous-redact-recording -> a string containing PII that should not be recorded by Meticulous
  .unlocalizableString -> a string that cannot (or does not need to be) localized
  .unlocalizedStringDebug -> a string that did not pass through our localization system
*/
.unlocalizedStringDebug:not(.unlocalizableString):not(
    .unlocalizableString *
  ):not(.meticulous-redact-recording):not(.meticulous-redact-recording *) {
  background-color: red;
}
