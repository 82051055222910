.TemplatePreviewContainer {
  display: flex;
  flex-direction: column;
  height: 555px;
  width: 100%;
}

.TemplatePreview {
  flex: 1;
  overflow: auto;
  padding: var(--dig-spacing__macro__medium);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.TemplatePreviewContent {
  background: var(--dig-color__background__base);
  border-radius: var(--dig-spacing__micro__medium);
  padding: 60px;
  box-shadow: 0px 16px 32px 0px var(--dig-color__shadow__elevated);
}
