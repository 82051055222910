.profileContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 8px;
  padding: 8px;
}

.deactivatedContainer {
  background-color: var(--dig-color__disabled__on-base);
}

.deactivatedName {
  color: var(--dig-color__border__bold);
}

.profileLink {
  color: inherit;
  border-radius: 8px;
}

.avatarContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
}

.textContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
