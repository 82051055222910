.AssistantFileViewerToolbar {
  z-index: 1;
  background-color: var(--dig-color__background__base);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: var(--dig-spacing__micro__small);
  padding-inline: var(--dig-spacing__micro__medium);
  width: 100%;
  box-sizing: border-box;
}

.AssistantFileViewerToolbarPageControls {
  margin-left: auto;
  display: flex;
}

.AssistantFileViewerToolbarLink {
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-type__size__text__medium);
  font-weight: var(--dig-type__weight__strong);
  line-height: var(--dig-type__lineheight__text__medium);
  font-weight: 500;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  min-width: 0;
}

.AssistantFileViewerToolbarControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}

.truncateText {
  display: block;
  width: 100%;
}
