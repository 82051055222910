.FileViewerWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.FileViewerWrapper.Skeleton {
  background: var(--dig-color__background__subtle);
}

.FileViewerPreviewContent {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
