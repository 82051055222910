.commentContainer {
  border-radius: 4px 12px 12px 12px;
  display: flex;
  flex-direction: row;
  padding-bottom: var(--spacing__unit--1);
  width: 100%;
}

.commentAvatarContainer {
  margin-right: var(--spacing__unit--1_5);
}

.commentBodyContainer {
  width: 100%;
}

.commentInput::placeholder {
  color: var(--dig-color__text__subtle);
}

/* 
 *  TODO: This is almost certainly an accessibility violation (no visible focus), but is 
 *  common across nearly every input in Dash. I've flagged this with Design, but will leave
 *  it as-is for now.
 */
.commentInput,
.commentInput:focus {
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
}

.commentButtonContainer {
  margin-top: var(--spacing__unit--1);
}

.commentButtonContainer > :first-child {
  margin-right: var(--spacing__unit--0_5);
}
