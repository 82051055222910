.optionCardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.optionCard {
  border-radius: var(--dig-radius__medium);
  border: 1px solid var(--dig-color__border__subtle);
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__micro__medium);
  cursor: pointer;
}

.optionCard.selectedCard {
  border-color: var(--dig-color__primary__base);
}

.optionCard.disabledCard {
  border-color: var(--dig-color__border__subtle);
  cursor: not-allowed;
  position: relative;
}

.optionCard.disabledCard .badge {
  position: absolute;
  margin: 0;
  top: -8px;
  left: 12px;
  background-color: var(--dig-color__background__base);
}
