.onboardingChecklist {
  position: fixed;
  bottom: var(--dig-spacing__macro__small);
  right: var(--dig-spacing__macro__small);
  width: 296px;
  z-index: 100;
}

/* Shrink content for mobile */
@media (max-width: 599px) {
  .onboardingChecklist {
    width: auto;
    right: var(--dig-spacing__macro__xsmall);
    left: var(--dig-spacing__macro__xsmall);
    bottom: var(--dig-spacing__macro__xsmall);
  }
}
