/* TODO AYC: remove this once we have a proper height for the result row
likely will remove these styles once we use dig's typeahead component */
.resultRowWrapper {
  min-height: 32px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: default;
}

.resultRowWrapper.isSelected {
  background-color: var(--dig-color__opacity__surface);
}

.resultRowWrapper.mouseActive:hover {
  background-color: var(--dig-color__opacity__surface);
}
