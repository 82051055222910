.greeterHeaderWrapper {
  display: flex;
  padding-top: calc(
    var(--dig-spacing__macro__large) + var(--dig-spacing__macro__large)
  );
  padding-bottom: calc(
    var(--dig-spacing__macro__large) + var(--dig-spacing__macro__large)
  );
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--dig-spacing__macro__medium);
  align-self: stretch;
}

.greeterHeaderContainer {
  display: flex;
  padding-right: var(--dig-spacing__micro__xlarge);
  padding-left: var(--dig-spacing__micro__large);
  flex-direction: column;
  align-items: center;
  gap: var(--dig-spacing__micro__medium);
  align-self: stretch;
}

.iconContainer {
  display: flex;
  align-items: center;
}

.titleWrapper {
  margin: 0;
}
