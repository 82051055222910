.card.card {
  width: 100%;
  padding-top: var(--dig-spacing__macro__xsmall);
}

.step {
  background-color: var(--dig-color__opacity__surface);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--dig-spacing__micro__medium);
}

.step.active {
  background-color: var(--dig-color__secondary__base);
}

.step.completed {
  border: 1px solid var(--dig-color__secondary__base);
  background-color: var(--dig-color__opacity__surface);
}

.stepText {
  color: var(--dig-color__disabled__base);
}

.step.active .stepText {
  color: var(--dig-color__secondary__on-base);
}
