.container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: minmax(0, auto) minmax(0, 1fr);
  height: 100%;
  width: 100%;
  position: relative;
}

.canvasContainer {
  width: 100%;
  height: 100%;

  grid-column: 2; /* Starts at first column and ends before the second */
  grid-row: 2;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.canvasContainer.mobile {
  border-top: 0.5px solid var(--dig-color__border__subtle);
}

.canvas {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: relative;
}

.wrapper {
  --layout-banner-height: 50px;

  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - var(--dash-win32-app-titlebar-height, 0px));
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  background-color: var(--dig-color__background__base);
}

/*
 * There's a bad bug in iOS causing the bottom to always get cut off.
 *
 * Ref: https://stackoverflow.com/questions/70883986/scroll-on-ios-does-not-scroll-entirely-down
 *
 * Warning: Using "@supports (-webkit-touch-callout: none)" will cause
 * electron to hang.
 */
.wrapper.iOS {
  height: -webkit-fill-available;
}

.twoColumns {
  width: 100%;
  display: grid;
  overflow: hidden;
}

.leftSide {
  grid-column: 1;
  min-width: 0;
}

.rightSide {
  grid-column: 2;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}

.rightSide.augustRevision {
  overflow-y: auto;

  --app-top-nav__height: 80px;

  scroll-margin-top: var(--app-top-nav__height);
}

.rightSide.augustRevision.mobile {
  --app-top-nav__height: 128px;

  scroll-margin-top: var(--app-top-nav__height);
}

.rightSide.augustRevision .content {
  overflow: unset;
}

.rightSide.augustRevision .canvasContainer {
  /**
    * overflow-x: hidden; is still necessary to make grid
    * responsive so we're only unsetting overflow-y here
    */
  overflow-y: unset;
}

.rightSide.augustRevision .canvas {
  overflow: unset;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}

.content.withBanner {
  padding-bottom: var(--layout-banner-height);
}

.expandSidebarIcon {
  position: absolute;
  top: var(--dig-spacing__macro__small);
  z-index: 100;
  left: 0;
}

.signOutButton {
  position: fixed;
  right: 12px;
  bottom: 12px;
  opacity: 0;
}

.signOutButton:hover {
  opacity: 0.8;
}

.banner {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: var(--layout-banner-height);
  border-top: 1px solid var(--dig-color__border__subtle);
  z-index: 1;
}
