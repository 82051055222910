.AnswerCitationCard {
  padding: var(--dig-spacing__micro__medium) 4px;
}

.AnswerCitationCardMetadata {
  column-gap: 0px;
  margin-left: -4px;
}

.AnswerCitationCardTitle {
  display: block;
  /* static height regardless or 1 or 2 lines */
  height: 44px;
}
