.omniboxInputContainer {
  position: relative;
  border-radius: var(--omnibar-input-border-radius);
  border: 1px solid var(--dig-color__border__subtle, rgba(0, 0, 0, 0.14));
  background: var(--dig-color__background__base);
  box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, 0.06);
}

.omniboxInputContainer.hasResultContainer {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* Styles for TextArea used for Omnibox Input
NOTE: may move this out later
 */
.omniboxTextArea {
  --inputHeight: 24px;
  resize: none;
  overflow: hidden;
  white-space: nowrap;
  font-family: var(--dig-type__family__text);
  font-weight: var(--dig-type__weight__base);
  border: none;
  width: 100%;
  height: var(--inputHeight);
  line-height: var(--inputHeight);
}

.omniboxTextArea:focus {
  outline: none;
  box-shadow: none;
  border-color: transparent;
}
