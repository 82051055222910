.colorPicker.colorPicker {
  padding-left: 0;
  padding-right: 0;
  margin-left: -4px;
}

.comboInput:first-child {
  flex-grow: 1;
}

.comboInput:last-child {
  width: 185px;
}
