@keyframes popUp {
  0% {
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemContainer {
  list-style-type: none;
  width: 100%;
}

.container {
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  padding: var(--dig-spacing__micro__medium);
  margin-bottom: var(--dig-spacing__micro__small);
}

.container.animateIn {
  opacity: 0;
  animation-name: popUp, fadeIn;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.01, 0, 0, 1), linear;
  animation-fill-mode: forwards;
}

.container.isDesktop {
  padding: var(--spacing__unit--1);
  padding-right: var(--spacing__unit--1_5);
  margin-bottom: var(--spacing__unit--0_5);
}

.container.isMobile {
  background-color: var(--dig-color__background__base);
  margin-bottom: var(--spacing__unit--1);
}

.container.selected,
.container:focus {
  background-color: var(--dig-color__opacity__surface--state-1);
}

.container.hovered {
  background-color: var(--dig-color__opacity__surface);
}

.container.colorSubtle.selected,
.container.colorSubtle:focus,
.container.colorSubtle.hovered {
  background-color: var(--dig-color__opacity__surface);
}

.container.noMargin {
  margin: 0;
}

.container.noMargin {
  background-color: var(--dig-color__background__base);
  cursor: default;
}

/* icon and title wrapper */

.topRow {
  display: grid;
  grid-template-columns: var(--dig-spacing__macro__xlarge) 1fr auto;
  align-items: center;
  gap: 24px;
}

.isDesktop .topRow {
  gap: var(--spacing__unit--1_5);
}
/* icon */

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 48px;
}

.iconContainer.colorSubtle {
  color: var(--dig-color__text__subtle);
}

/* title and subtitle */

.textContainer {
  display: flex;
  flex-direction: column;
  gap: var(--dig-spacing__micro__xsmall);
}

.isDesktop .textContainer {
  margin: 0;
  gap: var(--dig-spacing__micro__xsmall);
}

.titleContainer {
  display: flex;
  width: 0;
  min-width: 100%;
}

.titleTextWrapper {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__xsmall);
  overflow: hidden;
}

.titleTextWrapper > span {
  display: flex;
  align-items: center;
  position: relative;
}

.titleTextWrapper > span:not(:last-child)::after {
  content: '·';
  margin-left: var(--spacing__unit--0_5); /* spacing before the dot */
  margin-right: var(--spacing__unit--0_5); /* spacing after the dot */
}

.titleTextWrapper.colorSubtle {
  color: var(--dig-color__text__subtle);
}

.titleText {
  display: inline-block;
  overflow: hidden;
}

/* Verified */

.verifiedIcon {
  color: var(--dig-color__primary__base);
  display: flex;
  align-items: center;
  margin-left: var(--dig-spacing__micro__xsmall);
}

/* actions */

.actions {
  align-self: center;
}

.actions.alignTop {
  align-self: start;
}

/* snippet text */

.snippetText {
  margin-left: 72px;
  margin-bottom: var(--dig-spacing__micro__xsmall);
  margin-top: var(--dig-spacing__micro__medium);
}

.isDesktop .snippetText {
  margin-left: 60px;
  margin-bottom: 0;
  margin-top: 0;
}

.snippetText.removeMargins {
  margin: 0;
}

.attachments {
  margin-left: 72px;
}

.attachmentLinks {
  display: flex;
  flex-wrap: wrap;
  gap: var(--dig-spacing__micro__small);
  margin-top: var(--dig-spacing__micro__xsmall);
}

.attachmentButton {
  /* End button resets */

  flex: 0 1 auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  overflow: hidden;
  border: 0.5px solid var(--dig-color__border__subtle);
  border-radius: var(--spacing__unit--1);
  padding-right: var(--spacing__unit--2);

  text-decoration: none;
}

.attachmentButton:hover {
  background-color: var(--dig-color__secondary__surface);
}

.attachmentButton span {
  width: fit-content;
  height: fit-content;
}

.attachmentTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  max-width: 240px;
}
