.container {
  background: var(--dig-color__background__raised);
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__medium);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  margin-top: var(--dig-spacing__dimension__4);
  padding: var(--dig-spacing__dimension__16) 0;
}

.teamMembers {
  margin-top: var(--dig-spacing__dimension__8);
}

.suggestedPeopleLabel {
  padding: 0 var(--dig-spacing__dimension__16);
}

.contactRow {
  gap: var(--dig-spacing__dimension__8);
  padding: var(--dig-spacing__dimension__4) var(--dig-spacing__dimension__16);
  transition: all var(--duration__surface) var(--easing__transition);
  cursor: pointer;
}

.avatar {
  flex: 0 0 34px;
}

.contactRow:hover {
  background-color: var(--dig-color__secondary__surface--state-1);
}

.contactName,
.contactEmail {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.contactEmail {
  color: var(--dig-color__text__subtle);
}
