.stackNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__micro__xlarge)
    var(--dig-spacing__micro__small);
}

.stackNavBarLeft {
  display: flex;
  align-items: center;
}

.stackNavBarRight {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__small);
}
