.searchHeaderContainer {
  flex: 1;
  overflow: hidden;
  border-radius: var(--omnibar-input-border-radius);
  z-index: 10;
  position: relative;
}

.searchBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--dig-spacing__dimension__12);
  padding: 12px 16px;
  box-sizing: border-box;
  border: 1px solid transparent;
  height: 48px;
  min-width: 100%;
  background-color: var(--dig-color__background__base);
}

.searchBarContainer.disabled {
  border: 0.5px solid var(--dig-color__disabled__base);
  padding: 11.5px 15.5px;
  background: var(--dig-color__background__subtle);
}

.searchInputContainer {
  display: flex;
  flex: 1;
  font-family: 'Atlas Grotesk';
  justify-content: flex-end;
  gap: var(--dig-spacing__micro__xsmall);
  align-items: center;
  position: relative;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.searchInputContainer.fullWidthSearchbar {
  gap: var(--dig-spacing__dimension__4);
}

.searchInputEl {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;
  color: var(--dig-color__text__base);
  font-family: var(--type__body__large--fontfamily);
}

.searchInputEl.fullWidthSearchbar {
  font-size: 21px;
  line-height: 1.2;
}

.searchInputEl::placeholder {
  opacity: 0;
}

.searchInputEl.disabled::placeholder {
  color: var(--dig-color__disabled__base);
}

.fakePlaceholder {
  opacity: 0;
  left: 2px;
  top: auto;
  bottom: auto;
  display: flex;
  align-items: center;
}

.searchInputEl:placeholder-shown ~ .fakePlaceholder {
  opacity: 1;
}

.suffixElement {
  display: flex;
  position: absolute;
  font-family: var(--type__body__large--fontfamily);
  color: var(--dig-color__text__subtle);
  pointer-events: none;
  gap: 8px;
}

.suffixElement.fullWidthSearchbar {
  font-size: 16px;
  line-height: 24px;
}

.searchInputEl::selection,
.autocompleteHighlight {
  background-color: var(--dig-color__highlight__blue);
}

.fakeAutocomplete {
  max-width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fakeAutocomplete.fullWidthSearchbar {
  max-width: 540px;
}
