.listItem {
  border-radius: var(--dig-radius__xlarge);
  border: 1px solid var(--dig-color__border__subtle);
  cursor: pointer;
  word-break: break-word;
  background-color: var(--dig-color__background__base);
  margin: 0;
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__macro__small);
  /* Using fixed height so that height is the same with and without description */
  height: 80px;
  transition: all var(--duration__surface) var(--easing__transition);
}

.listItem:hover,
.listItem:focus {
  background-color: var(--dig-color__background__subtle);
  outline: none;
}

.flexCenter {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing__unit--2);
}

.iconMarginRight {
  display: flex;
  margin-right: var(--spacing__unit--1);
}

.icon {
  background-color: var(--dig-color__opacity__surface--state-1);
  border-radius: 50%;
  padding: 2px;
  height: 32px;
  width: 32px;
}

.listItem:not(.connected):hover .icon,
.listItem:not(.connected):focus .icon {
  opacity: 1;
}

.listItem:not(.connected) .icon {
  transition: all var(--duration__surface) var(--easing__transition);
  opacity: 0;
}

.icon.connected {
  background-color: var(--dig-color__identity__green);
}

.allowDisconnect.listItem.connected:hover .icon,
.allowDisconnect.listItem.connected:focus .icon {
  background-color: var(--dig-color__alert__surface--state-1);
}
