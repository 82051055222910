:root {
  /* These vars are overwritten by TopNav for august revision, once rolled out, remove those values */
  --dash-typeahead__border-radius: 20px;
  --dash-typeahead__max-width: 664px;
}

/* Main container */
.searchHeaderContainer {
  flex: 1;
  overflow: hidden;
  border-radius: var(--dash-typeahead__border-radius);
  z-index: 10;
  position: relative;
  transition: box-shadow 0.2s;
}

.searchHeaderContainer.typeaheadIsOpen {
  /* This needs to be above any elements that we want ClickOutside to register on, like the left side-nav */
  z-index: 101 !important;
}

.fullWidthSearchbar {
  --dash-typeahead__border-radius: var(--dig-spacing__micro__small);
}

.searchHeaderContainer > .dig-ProgressBar-track {
  background-color: var(--dig-color__border__base);
}

.searchHeaderContainer > .dig-ProgressBar-complete {
  background-color: var(--dig-color__secondary__surface);
}

.searchHeaderContainer.fullWidthSearchbar {
  border-radius: var(--dash-typeahead__border-radius);
  border: 1px solid transparent;
}

.searchHeaderContainer.extendBottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: var(--dash-typeahead__border-radius);
  border-top-right-radius: var(--dash-typeahead__border-radius);
}

.searchHeaderContainer.extendBottom.fullWidthSearchbar {
  box-shadow: var(--boxshadow__elevation1);
  border: 1px solid var(--dig-color__border__subtle);
  border-bottom: 1px solid transparent;
}

.searchHeaderContainer.active {
  box-shadow: var(--boxshadow__elevation1);
}

/* Omnibox enabled don't show box shadow */
.searchHeaderContainer.active.isOmniboxEnabled {
  box-shadow: none;
}

.searchHeaderContainer.active.fullWidthSearchbar {
  box-shadow: none;
}

.searchHeaderContainer.fullWidthSearchbar.isDualModeLauncher {
  border: none;
}

/* Visual Search Bar */
.searchBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--dig-spacing__dimension__12);
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: var(--dash-typeahead__border-radius);
  border: 1px solid var(--dig-color__border__subtle);
  height: 48px;
  min-width: 100%;
  background-color: var(--dig-color__background__base);
}

.searchBarContainer.isOmniboxEnabled {
  border: 1px solid transparent;
}

.searchBarContainer.fullWidthSearchbar {
  border: 1px solid transparent;
  gap: var(--dig-spacing__dimension__4);
  height: 72px;
  padding: 11px 14px;
}

.searchBarContainer.disabled {
  border: 0.5px solid var(--dig-color__disabled__base);
  padding: 11.5px 15.5px;
  background: var(--dig-color__background__subtle);
}

.searchBarContainer.focused {
  border: 1px solid var(--dig-color__background__subtle);
  padding: 9px 13px;
}

.searchBarContainer.focused.isOmniboxEnabled {
  border: 1px solid transparent;
}

.searchBarContainer.focused.fullWidthSearchbar {
  border: 1px solid var(--dig-color__background__base);
  padding: 11px 14px;
}

.searchBarContainer.focused.isOmniboxEnabled {
  border: 1px solid transparent;
}

.searchBarContainer.extendBottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: var(--dash-typeahead__border-radius);
  border-top-right-radius: var(--dash-typeahead__border-radius);
  border-bottom: 1px solid var(--dig-color__background__base);
}

.searchBarContainer.extendBottom.fullWidthSearchbar {
  border-top-left-radius: var(--dig-spacing__micro__small);
  border-top-right-radius: var(--dig-spacing__micro__small);
  border-bottom: 1px solid var(--dig-color__background__base);
}

.searchIconContainer {
  width: var(--dig-spacing__dimension__32);
  height: var(--dig-spacing__dimension__32);
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchIconContainer.fullWidthSearchbar {
  height: 50px;
  width: 50px;
}

/* Container for Search Icon and Input */
.searchInputContainer {
  display: flex;
  flex: 1;
  font-family: 'Atlas Grotesk';
  justify-content: flex-end;
  gap: var(--dig-spacing__micro__xsmall);
  align-items: center;
  position: relative;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.searchInputContainer.fullWidthSearchbar {
  gap: var(--dig-spacing__dimension__4);
}

.searchInputEl {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;
  color: var(--dig-color__text__base);
  font-family: var(--type__body__large--fontfamily);
}

.searchInputElHiddenText {
  /** Make the text fully transparent */
  color: rgba(0, 0, 0, 0) !important;
}

.suffixElement {
  display: flex;
  position: absolute;
  font-family: var(--type__body__large--fontfamily);
  color: var(--dig-color__text__subtle);
  pointer-events: none;
  gap: 8px;
}

.suffixElement.fullWidthSearchbar {
  font-size: 16px;
  line-height: 24px;
}

.searchInputEl::selection,
.autocompleteHighlight {
  background-color: var(--dig-color__highlight__blue);
}

.fakeAutocomplete {
  max-width: 320px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fakeAutocomplete.fullWidthSearchbar {
  max-width: 540px;
}

.searchInputEl.fullWidthSearchbar {
  font-size: 21px;
  line-height: 1.2;
}

.searchInputEl::placeholder {
  /* Intentionally doesn't render. Overwritten by fake placeholder */
  opacity: 0;
}

.showDefaultPlaceholder::placeholder {
  opacity: 1;
  color: var(--dig-color__text__subtle);
}

.searchInputEl.disabled::placeholder {
  color: var(--dig-color__disabled__base);
}

.searchActionsContainer {
  display: flex;
  flex: 0 0 auto;
  font-family: 'Atlas Grotesk';
  justify-content: flex-end;
  gap: 4px;
  align-items: center;
}

.placeholderKeyCap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  font-family: var(--type__body__monospace--fontfamily);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  border-radius: var(--dig-spacing__dimension__4);

  border: 1px solid var(--dig-color__border__subtle);
  padding: 0px var(--dig-spacing__dimension__4);
  margin-left: var(--dig-spacing__dimension__4);
  box-sizing: border-box;
}

.placeholderKeyCapSymbol {
  font-size: 20px;
  transform: translateY(-2px);
  font-weight: 1;
}

/* Placeholder animation classes */
.fakePlaceholder {
  opacity: 0; /* hides fake placeholder on text input */
  left: 2px;
  top: auto;
  bottom: auto;
  display: flex;
  align-items: center;
}

.searchInputEl:placeholder-shown ~ .fakePlaceholder {
  opacity: 1;
}

.teamDashLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
}
