.container {
  min-width: 240px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--dig-color__background__base);
}

.content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.modelSelector {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border: 1px solid var(--dig-color__border__default);
  border-radius: 4px;
  background: var(--dig-color__background__default);
  cursor: pointer;
  margin-right: 8px;
}

.modelSelector:hover {
  background: var(--dig-color__background__hover);
}
