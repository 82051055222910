.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 var(--spacing__unit--3);
  width: 100%;
  gap: var(--spacing__unit--2_5);
  overflow: hidden;
  animation: fadeIn 0.3s;
}

.contentContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  max-width: min(1100px, 100%);
  margin: 0 auto;
  gap: var(--spacing__unit--2_5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
