.modalHeader {
  padding: 0 !important;
}

.modalTitle {
  margin: 0;
}

.modalHeaderContainer {
  border-bottom: 1px solid var(--dig-color__border__subtle);
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.dividerVertical {
  width: 1px;
  height: 52px;
  background-color: rgba(219, 219, 219, 1);
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actionsContainer {
  margin-left: auto;
  display: flex;
  gap: 18px;
}

.paginationContainer {
  display: flex;
  align-items: center;
}

.dividerSmall {
  width: 1px;
  height: 32px;
  background-color: rgba(219, 219, 219, 1);
}

.modalBody {
  padding: 0;
}

.drawerBodyContent {
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__xlarge);
}

.previewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 86px);
  background-color: var(--dig-color__secondary__on-base);
}

.previewImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.infoButton {
  padding-top: 5px;
}

.filenameExtension {
  text-transform: uppercase;
}
