.sectionContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--dig-color__background__subtle);
  border-radius: var(--dash-radius__component);
  padding: var(--spacing__unit--2);
  position: relative;
}

.augustRevisionSectionContainer {
  background: none;
  gap: 0;
  padding-left: 0;
  padding-right: 0;
}

.emptySectionContainer {
  /* For empty sections, add extra padding on the bottom to center the header */
  padding-bottom: var(--dash-grid-card__padding-top);
}

.augustRevisionEmptySectionContainer {
  padding-bottom: var(--dig-spacing__macro__medium);
}

.sectionContainer > * {
  --dash-radius__component: var(--dash-radius__level-2);
}

.addButton {
  padding: 9px 8px;
  background-color: var(--dig-color__secondary__surface);
  border-radius: var(--spacing__unit--1_5);
  border: none;
  outline: none;
  align-items: center;
  text-align: left;
  display: flex;
  gap: var(--spacing__unit--1);
  color: var(--dig-color__text__subtle);
}

.defaultSectionHeader {
  margin-top: calc(
    -1 * (var(--dash-grid-card__padding-top) -
          var(--dash-grid-card__padding-bottom))
  );
}

.actionRow {
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__macro__small);
  flex-direction: row;
  justify-content: space-between;
}

.actionRow.actionRowAugust {
  margin-bottom: var(--dash-grid-card__row_gap);
  padding: 0 var(--dig-spacing__micro__large);
  background-color: transparent;
  position: relative;
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing__unit--1_5);
}

.actionGroup {
  display: flex;
  gap: var(--spacing__unit--0_5);
}

.actionGroupAugust {
  gap: var(--dig-spacing__micro__small);
}

.actionGroup:after {
  content: '';
  display: flex;
  border-right: 1px solid var(--dig-color__border__subtle);
  margin-left: var(--spacing__unit--1);
}

.actionGroup:last-child:after {
  display: none;
}

.linkList {
  gap: 0;
  padding: 4px 0; /* padding helps with the droparea line */
  overflow: visible; /* overflow allows the droparea line to render on top */
}

.accessoryButton {
  pointer-events: auto;
  color: var(--dig-color__text__subtle);
}
