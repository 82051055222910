.AddSourcesModalBody {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  height: 694px;
  position: relative;
  border-radius: var(--dig-radius__large);
  border: 1px solid var(--dig-color__border__subtle);
}

.AddSourcesModalContent {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;

  color: var(--dig-color__text__base);
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-type__size__text__medium);
  font-weight: var(--dig-type__weight__base);
  line-height: var(--dig-type__lineheight__text__medium);
}

.AddSourcesModalBody .AddSourcesModalContent {
  padding: var(--dig-spacing__macro__medium);
  overflow-y: scroll;
}

.AddSourcesModalWindow :global(.dig-Modal-close-btn) {
  border-radius: var(--dig-radius__small);
  right: 10px;
  top: 10px;
  color: var(--dig-color__text__subtle);
}

.AddSourcesModalWindow :global(.dig-Modal-close-btn):hover {
  background: var(--dig-color__background__base);
}

.AddSourcesModalBodyMobile .AddSourcesModalContent {
  height: calc(100vh - 82px);
}

.AddSourcesModalTitle {
  margin: 0 var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__small);
}

.AddSourcesModalDescription {
  margin: 0 var(--dig-spacing__micro__xsmall) var(--dig-spacing__micro__xsmall);
}

.AddSourcesModalControls {
  margin-bottom: var(--dig-spacing__micro__xsmall);
  display: flex;
  flex-flow: column;
}

.AddSourcesModalSourcesInputLabel {
  margin-bottom: var(--dig-spacing__micro__small);
}

.AddSourcesModalFooter {
  align-items: center;
  background: var(--dig-color__background__base);
  border-top: 1px solid var(--dig-color__border__subtle);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  gap: var(--dig-spacing__macro__small);
  justify-content: space-between;
  padding: var(--dig-spacing__micro__small);
  position: sticky;
  border-radius: 0 0 var(--dig-radius__large) var(--dig-radius__large);
}

.AddSourcesModalFooterLeft {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__small);
  margin-left: var(--dig-spacing__micro__medium);
}

.AddSourcesModalEsc {
  background: var(--dig-color__background__base);
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__small);
  padding: var(--dig-spacing__dimension__2) var(--dig-spacing__micro__small);
}

.AddSourcesModalFooterRight {
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__medium);
}

.AddSourcesModalDragOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 221px 95px 222px 95px;
  z-index: 10;
}

.AddSourcesModalDragOverlayDark {
  background: rgba(30, 30, 30, 0.5);
}

.AddSourcesModalDragTitle {
  color: var(--dig-color__text__base);
  font-family: var(--dig-type__family__title);
  font-size: var(--type__title__standard--fontsize);
  font-weight: 500;
  line-height: var(--dig-type__lineheight__text__medium);
  margin: var(--dig-spacing__micro__medium);
}

.AddSourcesModalDragDescription {
  color: var(--dig-color__text__base);
  font-family: var(--dig-type__family__text);
  font-size: var(--Size-Text-Medium, 14px);
  font-weight: 400;
  line-height: var(--dig-type__lineheight__text__medium);
  margin-top: var(--dig-spacing__micro__medium);
  margin-bottom: var (--dig-spacing__micro__medium);
}

.AddSourcesModalDragSubtext {
  color: var(--dig-color__text__subtle);
  font-family: var(--dig-type__family__text);
  font-size: var(--Size-Text-Small, 12px);
  font-weight: 400;
  line-height: var(--Line-Height-Paragraph-Small, 20px);
}

.AddSourcesModalDragIcon {
  margin-bottom: var(--dig-spacing__macro__small);
}

.AddSourcesModalBodyDragging * {
  pointer-events: none;
}
