.accordionHeader {
  all: unset;
  width: 100%;
  cursor: pointer;
}

.gapSpacing {
  gap: 8px;
}

.listContainer {
  gap: 2px;
  min-height: 40px;
}

.titleRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.title {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  hyphens: none;
}

.modifiedDate {
  flex: 0 0 auto;
  white-space: nowrap;
}

.modifiedDate:before {
  content: '•';
  margin: 0 8px;
}

.listItem {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding-left: 2px;
  padding-right: 2px;
}

.clickableListItem:hover {
  background-color: var(--dig-color__opacity__surface);
}

.loadingText {
  margin: var(--dig-spacing__dimension__4) 0;
}
