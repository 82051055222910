.link,
.link:focus,
.link:focus-within {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing__unit--0_5);
  flex-grow: 1;

  justify-content: space-between;
  text-overflow: ellipsis;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  /**
   * DIG links do weird styling with negative margins that we need to account for when focused,
   * otherwise the link shifts and/or spreads out of its parent and gets cut off by overflow. The
   * below fixes pad out the link and then shift it with negative margin so that there's room for its
   * focus ring but it doesn't appear to move.
   */
  margin: 0;
}

:global(.dig-Link).link:hover {
  text-decoration: none;
}

.linkHeight--small {
  height: 40px;
}

.linkHeight--large {
  height: 56px;
}

.linkHeightMarch {
  height: auto;
}

.linkHeightMarch,
.linkHeightMarch:focus {
  padding: var(--dash-link-list__link__padding-top)
    var(--dash-link-list__link__padding-right)
    var(--dash-link-list__link__padding-bottom)
    var(--dash-link-list__link__padding-left);
}

.faviconTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  gap: var(--spacing__unit--2);
  width: 100%;
}

.linkHeight--small .faviconTitleContainer {
  gap: var(--spacing__unit--0_5);
}

.linkHeight--xlarge .faviconTitleContainer {
  gap: var(--spacing__unit--2);
}

.accessoryButtonContainer {
  right: 0;
  top: 0px;
  height: 100%;
  position: absolute;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  gap: var(--spacing__unit--0_5);
  /* Allow for clicking through to DigLink. The clickable buttons in the
    accessory component should reset pointer-events to auto */
  pointer-events: none;

  color: var(--dig-color__text__base);
}

.linkHeightMarch + .accessoryButtonContainer {
  right: var(--dash-link-list__link__padding-right, 0);
}

.accessoryButton {
  pointer-events: auto;
}

.linkContainer {
  position: relative;
  border-radius: var(--dash-radius__component);
  overflow: hidden;
}

.linkContainer > * {
  --dash-radius__component: var(--dash-radius__level-3);
}

/*
 * The following styles are used to visually hide the accessory buttons when the
 * link is not focused or hovered. It also hides only if the accessory buttons
 * are explicitly showing. This is necessary to allow the buttons to be read by screen
 * readers when the user focuses into the link.
 */
.linkContainer:not(:hover):not(:focus-within)
  .accessoryButtonContainer:not(.accessoryButtonContainerHoverState) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  width: 1px;
}

.linkContainer:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: var(--dash-radius__component);
  /* TODO: Temporarily disable while we figure out proper experience */
  /* transition: background-color var(--duration__non-motion)
    var(--easing__transition); */
}

.linkContainer:hover:before,
.linkContainer:focus-within:before {
  background-color: var(--dig-color__opacity__surface);
}

.isLoadingLink {
  opacity: 0.5;
}

.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.linkSpinner {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: var(--spacing__unit--1);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.titleContainerLoading {
  flex-grow: 1;
}

.linkTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing__unit--1);
  overflow: hidden;
  line-height: var(--spacing__unit--3);
  white-space: nowrap;
}

.linkMetadata {
  display: flex;
  flex-wrap: wrap;
}

.linkMetadata > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkMetadata > span + span:before {
  content: '•';
  margin: 0 var(--spacing__unit--1);
}

.linkMetadataTruncatable {
  white-space: pre-wrap;
}

.plusNMore {
  overflow: hidden;
  padding: var(--spacing__unit--0_5);
}

.tooltipList {
  max-width: 300px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.textContainer {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-grow: 1;
}

.faviconContainer {
  flex-shrink: 0;
  display: inline-flex;
}

.colorfulFavicon {
  background-color: var(--dig-color__primary__surface);
}

.favicon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.faviconLarge {
  width: 24px;
  height: 24px;
}

.faviconSmall {
  width: 16px;
  height: 16px;
}

.loadingFavicon {
  width: 100%;
  height: 100%;
}

.reactRouterLink {
  text-decoration: none;
}

.bodyContent {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  pointer-events: auto;
  padding-right: var(--spacing__unit--1);
}

.bodyContent > *:last-child {
  margin-bottom: var(--spacing__unit--1_5);
}

.bodyContentSize--small {
  margin-left: calc(
    var(--dash-link-list__link__padding-left) + var(--dig-spacing__macro__small) +
      var(--spacing__unit--0_5)
  );
}

.bodyContentSize--medium {
  margin-left: calc(
    var(--dash-link-list__link__padding-left) +
      var(--dig-spacing__macro__medium) + var(--spacing__unit--1)
  );
}

.bodyContentSize--large {
  margin-left: calc(
    var(--dash-link-list__link__padding-left) + var(--dig-spacing__macro__large) +
      var(--spacing__unit--2)
  );
}

.bodyContentSize--xlarge {
  margin-left: calc(
    var(--dash-link-list__link__padding-left) +
      var(--dig-spacing__macro__xlarge) + var(--spacing__unit--2)
  );
}

.thumbnailOverlay {
  display: none;
  pointer-events: none;
  border-radius: var(--dig-radius__medium);
  box-shadow: 0px 4px 12px 0px var(--dig-color__shadow__elevated);
  max-width: 350px;
  max-height: 200px;
}

.thumbnailOverlay > img {
  max-width: 350px;
  max-height: 200px;
  object-fit: contain;
  border-radius: var(--dig-radius__medium);
  border: 1px solid var(--dig-color__border__subtle);
}

.linkContainer:focus-within + .thumbnailOverlay,
.linkContainer:hover + .thumbnailOverlay {
  /* thumbnail overlay offset is used to position overlay slightly into the link so theres a small overlap*/
  --thumbnail-overlay-offset: 10px;
  max-width: 350px;
  max-height: 200px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)
    translateY(calc(-100% + var(--thumbnail-overlay-offset)));
  margin-bottom: var(--spacing__unit--1);
  z-index: 1000;
}
