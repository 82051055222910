.ConversationMessageActionsContainer {
  margin-top: -4px;
}

.ConversationMessageActionsContainerCondensed {
  margin-left: 0;
}

.ConversationMessageActionsButton {
  width: var(--spacing__unit--2);
  height: var(--spacing__unit--2);
}
