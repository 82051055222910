.progressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes popUp {
  0% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}

.annotationContainer {
  background-color: var(--dig-color__warning__surface--state-2);
}

.conversationMode {
  height: fit-content;
  max-height: none !important;
  overflow: visible;
}

.conversationMode .cardContent {
  overflow: visible;
}

.followUpLink {
  text-decoration: none;
}
