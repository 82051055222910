/* Styles shared between success and partial success modal */
/* Includes styles for invitee row */

:root {
  /* Limits the height of the invitee list to 5 entries as specified by design */
  /* Height calculation: (24px height + 8px top margin + 8px bottom margin) * 5 items */
  --invitee-list-max-height: 200px;
}

.inviteeListElement {
  display: flex;
  flex-direction: row;
  height: 24px; /* Set explicit height for the element */
  margin: var(--dig-spacing__micro__small) 0;
  gap: var(--dig-spacing__dimension__4);
}

.inviteePendingStatus {
  margin-left: var(--dig-spacing__micro__small);
}

/* Styles for success modal */

.inviteeListContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--dig-spacing__micro__small);
  margin-bottom: var(--dig-spacing__micro__large);
  max-height: var(--invitee-list-max-height);
  overflow-y: auto;
}

.sentTitleContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--dig-color__success__surface);
  width: 100%;
  align-items: center;
  justify-content: center;
}

.inviteSentText {
  margin-bottom: var(--dig-spacing__macro__large);
  /* 40 px */
  color: var(--dig-color__success__on-surface);
  font-weight: var(--type__title__small--fontweight_strong);
}

.animatedCheckmark {
  margin-top: var(--dig-spacing__macro__large);
  color: var(--dig-color__success__on-surface);
  animation: bounce 0.25s ease-in-out 0.25s both;
}

@keyframes bounce {
  0% {
    transform: scale(0, 0);
  }

  75% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: none;
  }
}

/* Styles for partial success modal */

.inviteTitle {
  padding-left: var(--dig-spacing__dimension__16);
  padding-right: var(--dig-spacing__dimension__16);
}

.successInviteListContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--dig-spacing__micro__large);
  padding-left: var(--dig-spacing__dimension__16);
  padding-right: var(--dig-spacing__dimension__16);
  max-height: var(--invitee-list-max-height);
  overflow-y: auto;
}

.failedInviteContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--dig-color__alert__surface);
  padding: var(--dig-spacing__dimension__24);
  border-radius: var(--dig-radius__xlarge);
  gap: var(--dig-spacing__dimension__8);
}

.failedInviteList {
  display: flex;
  flex-direction: column;
  max-height: var(--invitee-list-max-height);
  overflow-y: auto;
}

.failedInviteTitle {
  margin: 0;
}
