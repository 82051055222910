.TemplateSettingsModalWindow {
  height: 694px;
  max-width: 1083px;
  width: 1083px;
}

.TemplateSettingsModalWindow :global(.dig-Modal-close-btn) {
  border-radius: var(--dig-radius__small);
  right: 12px;
  top: 12px;
}

.TemplateSettingsModalBody {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px;
}

.TemplateSettingsModalHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--dig-spacing__macro__small) var(--dig-spacing__micro__xlarge)
    var(--dig-spacing__macro__xsmall);
  border-bottom: 1px solid var(--dig-color__border__subtle);
}

.TemplateSettingsModalHeaderTitle {
  font-family: var(--dig-type__family__title);
  font-size: 22px;
  padding: 4px 2px 0px;
}

.TemplateSettingsModalContent {
  display: flex;
  flex: 1;
  min-height: 0;
  overflow: hidden;
}

.TemplateSettingsModalLeftPanel {
  width: 320px;
  min-width: 320px;
  border-right: 1px solid var(--dig-color__border__subtle);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.TemplateSettingsModalRightPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 0;
}

.TemplateSettingsModalPreview {
  flex: 1;
  padding: var(--dig-spacing__macro__medium) var(--dig-spacing__macro__xlarge);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 570px;
}

.TemplateSettingsModalPreviewTitle {
  margin-bottom: var(--dig-spacing__micro__small);
}

.TemplateSettingsModalPreviewDescription {
  color: var(--dig-color__text__subtle);
  margin-bottom: var(--dig-spacing__micro__medium);
}

.TemplateSettingsModalPreviewContent {
  flex: 1;
  border-radius: var(--dig-spacing__micro__medium);
  background: var(--dig-color__background__base);
  border: 1px solid var(--dig-color__border__subtle);
  padding: var(--dig-spacing__macro__large);
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-font-size__small);
  line-height: 1.5;
  white-space: pre-wrap;
  overflow-y: auto;
  min-height: 700px;
}

.TemplateSettingsModalFooter {
  align-items: center;
  background: var(--dig-color__background__base);
  border-top: 1px solid var(--dig-color__border__subtle);
  display: flex;
  justify-content: flex-end;
  padding: var(--dig-spacing__micro__small);
  border-radius: 0 0 var(--dig-radius__large) var(--dig-radius__large);
}

.TemplateSettingsModalButton {
  margin-right: var(--dig-spacing__micro__small);
}

/* Custom Markdown Preview Overrides */
.TemplateSettingsModalPreviewContent p {
  margin: 0.25em 0;
  line-height: 1.3;
}

.TemplateSettingsModalPreviewContent h1,
.TemplateSettingsModalPreviewContent h2,
.TemplateSettingsModalPreviewContent h3,
.TemplateSettingsModalPreviewContent h4,
.TemplateSettingsModalPreviewContent h5,
.TemplateSettingsModalPreviewContent h6 {
  margin: 0.1em 0;
  line-height: 1.1;
}

.TemplateSettingsModalPreviewContent ul,
.TemplateSettingsModalPreviewContent ol {
  margin: 0;
  padding-left: 24px;
  white-space: normal;
}

.TemplateSettingsModalPreviewContent li {
  margin: 0.05em 0;
  line-height: 1.3;
  overflow-wrap: break-word;
}

.TemplateSettingsModalPreviewContent li p {
  margin: 0;
}
