.headerContainer {
  padding-bottom: var(--spacing__unit--2);
}

.header {
  font-size: var(--dig-type__size__text__medium);
  font-weight: var(--dig-type__weight__strong);
  color: var(--dig-color__text__subtle);
}

.subheader {
  margin-left: var(--dig-spacing__micro__medium);
  font-size: var(--dig-type__size__text__medium);
  color: var(--dig-color__text__subtle);
}

.connectorsContainer {
  display: grid;
  gap: var(--dig-spacing__micro__large);
  width: 100%;
}

.connectorDescription {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--dig-spacing__micro__small);
}
