.highlightContainer {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.lineClamp1 {
  line-clamp: 1;
  -webkit-line-clamp: 1;
}
