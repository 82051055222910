.ConversationMessagesScroller {
  flex: 1 1 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 18px 20px 0px 20px;
  width: 100%;
  box-sizing: border-box;
}

.ConversationMessagesScrollerCondensed {
  padding: 18px 20px 0px 16px;
}

.ConversationMessagesScroller.ConversationMessagesScrollerNoPadding {
  padding: 0;
}

.ConversationMessages {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 720px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding-bottom: var(--dig-spacing__macro__medium);
}

.ConversationMessagesScroller.ConversationMessagesScrollerNoPadding
  .ConversationMessages {
  margin: 0;
  padding-bottom: 0;
}

.LoadingMessageRow {
  border-radius: var(--dig-radius__large);
  display: flex;
  gap: 12px;
  width: max-content;
  color: var(--dig-color__text__base);
}

.ConversationMessageRow {
  margin: 8px 0 6px;
  animation: bubbleAppear 0.4s ease forwards;
  font-size: var(--dig-type__size__text__large);
}

.ConversationMessageRow.ProgressStepHiding {
  animation: fadeOut 1s ease forwards;
}

.ConversationMessageRow.ProgressStepHidden {
  display: none;
}

.ConversationMessageRowCondensed {
  max-width: 100%;
  font-size: var(--dig-type__size__text__large);
}

.ConversationMessageRowOpacity {
  opacity: 0.4;
}

.ConversationMessageRowOpacityUnmounting {
  animation: fadeOut 0.3s ease-out forwards;
  pointer-events: none;
}

@keyframes fadeOut {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0;
  }
}

.ConversationMessageRowUser {
  align-self: flex-end;
  border-radius: var(--dig-radius__xlarge);
  background: var(--dig-color__opacity__surface);
  min-width: 60px;
  max-width: 75%;
  width: fit-content;
  padding: 8px 16px;
  overflow: hidden;
  font-size: var(--dig-type__size__text__large);
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0px;
}

.ConversationMessageSources {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ConversationMessageSourcesUser {
  align-items: flex-end;
}

.ConversationMessageSourceFileViewer,
.ConversationMessageSourceRow {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__xlarge);
  background-color: var(--dig-color__background__base);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  gap: 12px;
  overflow: hidden;
}

.ConversationMessageSourceFileViewer {
  height: 300px;
  width: 372px;
}

.ConversationMessageSourceRow {
  width: 272px;
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__xlarge)
    var(--dig-spacing__micro__medium) var(--dig-spacing__micro__small);
}

.ConversationMessageSourcePadBottom {
  margin-bottom: var(--dig-spacing__micro__large);
}

.ConversationMessageTitle {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: fit-content;
  font-size: var(--dig-type__size__text__medium);
}

.ConversationMessageSourceInfo {
  overflow: hidden;
  flex: 1;
  margin-left: 0;
}

.ConversationMessageSourceMetadata {
  display: flex;
  align-items: center;
  min-width: 100%;
  width: 0;
  overflow-x: hidden;
  text-wrap: nowrap;
}

.ConversationMessageSourceMetadata > div {
  display: flex;
  align-items: center;
  position: relative;
}

.ConversationMessageSourceMetadata > div:not(:last-child)::after {
  content: '·';
  margin-left: var(--spacing__unit--0_5);
  margin-right: var (--spacing__unit--0_5);
}

.ComposeSourceActionButton {
  width: 24px;
  height: 24px;
  visibility: hidden;
}

.ConversationMessageSourceRow:hover .ComposeSourceActionButton {
  visibility: visible;
}

.ConversationMessageSourceTitle {
  min-width: 0;
  flex: 1 1 auto;
  font-size: var(--dig-type__size__text__medium);
}

.ConversationMessageRowMessageActionText {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  transition: all 0.3s ease;
}

.ConversationMessageRowMessageActionIconContainer {
  background: var(--dig-color__background__base);
  border-radius: 50%;
  width: 24px;
  border: 1px solid var(--dig-color__border__subtle);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  font-size: var(--dig-type__size__text__large);
}

.ConversationMessageRowCondensed
  .ConversationMessageRowMessageActionIconContainer {
  display: none;
}

.ConversationMessageRowMessageActionIconContainerDone {
  background: var(--dig-color__primary-cta__base);
}

.ConversationMessageRowMessageActionIconEmptyContainer {
  width: 26px;
  height: 26px;
  flex: 0 0 auto;
}

.ConversationMessageRowMessageActionIcon {
  flex: 0 0 auto;
  color: var(--dig-color__text__subtle);
}

.ConversationMessageRowMessageActionIconDone {
  color: var(--dig-color__primary__on-base);
}

.ConversationMessageRowPlainText {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  min-width: 0;
  flex: 0 1 auto;
}

.ConversationMessageRowMarkdown {
  margin-top: -6px;
}

.ConversationMessageActionContextText {
  color: var(--dig-color__text__subtle);
  margin-bottom: -10px;
}

@keyframes bubbleAppear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ConversationMessageRowMessageAnimation {
  opacity: 0;
  animation: bubbleAppear 0.4s ease forwards;
}

.SearchQueryChip,
.SearchResultChip {
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 28px;
  padding: 0px 12px;
}

.ChipContent {
  color: var(--dig-color__text__subtle);
  font-size: var(--dig-type__size__text__medium);
  font-style: normal;
  font-weight: 400;
  line-height: var(--dig-type__lineheight__label__small);
}

.SearchResultChipIcon {
  transform: scale(0.8);
  width: 16px;
  margin-left: -4px;
}

.ConversationMessageRowSubMessageTitle {
  margin-bottom: 8px;
}

.ConversationMessageRowSubMessageTitleSearch {
  margin-top: 8px;
}

.ComposeSelectionEditContext {
  gap: 8px;
  margin-top: 8px;
  /* TODO:(rich) enable this once the UI is ready */
  display: none;
}

.ComposeSelectionEditContextIcon {
  flex: 0 0 auto;
  color: var(--dig-color__text__subtle);
}

/* Custom Markdown */
.ConversationMessageRowMarkdown h1 {
  padding-top: var(--dig-spacing__micro__small);
  padding-bottom: var(--dig-spacing__micro__small);
  margin-top: var(--dig-spacing__micro__small);
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-type__size__text__xlarge);
  font-weight: var(--dig-type__weight__strong);
  line-height: var(--type__body__large--lineheight_paragraph);
}

.ConversationMessageRowMarkdown h2 {
  margin-bottom: 0;
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-type__size__text__large);
  font-weight: var(--dig-type__weight__strong);
  line-height: var(--dig-type__lineheight__label__large);
}

.ConversationMessageRowMarkdown h3 {
  font-family: var(--dig-type__family__text);
  font-size: var (--dig-type__size__text__medium);
  font-weight: var(--dig-type__weight__strong);
  line-height: var (--dig-type__lineheight__label__large);
}

.ConversationMessageRowMarkdown h4,
.ConversationMessageRowMarkdown h5,
.ConversationMessageRowMarkdown h6 {
  font-family: var(--dig-type__family__text);
  font-size: var(--dig-type__size__text__medium);
  font-weight: var(--dig-type__weight__strong);
  line-height: var(--dig-type__lineheight__title__large);
}

.ConversationMessageRowMarkdown p {
  padding: var(--dig-spacing__micro__small) 0;
  margin: 0;
  font-size: var(--type__body__large--fontsize);
}

.ConversationMessageRowMarkdownCondensed p {
  font-size: var(--dig-type__size__text__medium);
}

.ConversationMessageRowMarkdown p:last-child {
  padding-bottom: 0;
}

.ConversationMessageRowMarkdown strong {
  font-weight: var(--dig-type__weight__strong);
}

.ConversationMessageRowMarkdown ul,
.ConversationMessageRowMarkdown ol {
  padding-top: var(--dig-spacing__micro__small);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 24px;
  line-height: 1.6em;
  margin-bottom: 1.2em;
  padding-left: 0;
}

.ConversationMessageRowMarkdown li {
  padding-left: 0px;
  padding-bottom: 8px;
}

.ConversationMessageRowMarkdown blockquote {
  margin: 0;
  padding: var(--dig-spacing__micro__medium) 0;
}

.ConversationMessageRowMarkdown table {
  width: 100%;
  border-collapse: collapse;
}

/* pre is codeblock container */
.ConversationMessageRowMarkdown pre {
  padding: 0.5em;
  margin: 0.5em 0;
  overflow: auto;
  border-left: var(--dig-spacing__micro__xsmall) solid
    var(--dig-color__border__subtle);
  white-space: pre-wrap;
}

/* styling code text */
.ConversationMessageRowMarkdown code {
  padding: 0;
  margin: 0;
  font-family: var(--dig-type__family__monospace);
  font-weight: var(--dig-type__weight__monospace);
  line-height: var(--dig-type__lineheight__label__small);
  font-size: var (--dig-type__size__text__medium);
  background-color: var(--dig-color__background__subtle);
  border: 0.5px solid var(--dig-color__border__subtle);
  border-radius: 5px;
  padding: 2px 4px;
}

/* styling code text inside code block */
.ConversationMessageRowMarkdown pre code {
  background: var(--dig-color__background__base);
  color: var(--dig-color__text__subtle);
  font-weight: var(--dig-type__weight__base);
  border: unset;
  border-radius: inherit;
  padding: inherit;
}
