.stackDescription {
  word-break: break-word;
  display: block;
  cursor: default;
  max-width: 600px;
}

.stackName {
  font-size: var(--type__title__large--fontsize);
  margin: 0;
  margin-top: calc(-1 * var(--spacing__unit--0_5));
  display: inline-flex;
  align-items: flex-end;
  gap: var(--spacing__unit--0_5);
  text-overflow: ellipsis;
  max-width: 100%;
  word-break: break-word;
}

.stackNameAugRev {
  font-weight: 500;
  color: var(--dig-color__text__base);
  margin: 0;
  margin-top: calc(-1 * var(--spacing__unit--0_5));
  display: inline-flex;
  align-items: flex-end;
  gap: var(--spacing__unit--0_5);
  text-overflow: ellipsis;
  max-width: 600px;
  word-break: break-word;
  /* Padding needed to keep text from feeling cramped */
  padding: 2px;
}

.stackDescriptionAugRev {
  word-break: break-word;
  display: block;
  cursor: default;
  max-width: 600px;
  line-height: var(--dig-type__lineheight__label__medium);
  /* Padding needed to keep text from feeling cramped */
  padding: 2px;
  white-space: pre-wrap;
}

.stackDescriptionAugRev.stackDescriptionChat {
  line-height: unset;
}

.stackNameInput {
  font-size: var(--type__title__large--fontsize);
  font-family: var(--type__title__large--fontfamily);
  line-height: var(--type__title__xlarge--fontsize);
  border: 1px solid var(--dig-color__border__subtle);
}

.stackNameInputAugRev {
  font-size: var(--dig-type__size__title__medium);
  font-family: var(--dig-type__family__title);
  line-height: var(--dig-type__lineheight__title__medium);
  border: 0;
  font-weight: 500;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 600px;
  /* Padding needed to keep text from feeling cramped */
  padding: 2px;
  margin: 0;
  margin-bottom: -12px;
  margin-top: -4px;
}

.stackNameInputAugRev.stackNameInputChat {
  font-size: var(--dig-type__size__title__large);
  font-family: var(--dig-type__family__title);
  line-height: var(--dig-type__lineheight__title__large);
}

.stackNameInputAugRev:global(.dig-TextArea),
.stackNameInputAugRev:global(.dig-TextArea):focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.stackNameInputAugRev:global(.dig-TextArea)::placeholder {
  color: var(--dig-color__border__base);
}

.stackDescriptionInputAugRev {
  font-size: var(--dig-type__size__text__medium);
  font-family: var(--dig-type__family__text);
  line-height: var(--dig-type__lineheight__label__medium);
  font-weight: 400;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 600px;
  /* Padding needed to keep text from feeling cramped */
  padding: 2px;
  margin-bottom: -4px;
}

.stackDescriptionInputAugRev:global(.dig-TextArea),
.stackDescriptionInputAugRev:global(.dig-TextArea):focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.stackDescriptionInputAugRev:global(.dig-TextArea)::placeholder {
  color: var(--dig-color__border__base);
}

.stackDescriptionInputAugRev.stackDescriptionInputChat {
  font-size: var(--dig-type__size__text__large);
  font-family: var(--dig-type__family__text);
  line-height: var(--dig-type__lineheight__label__large);
}

.alignSeeMore {
  margin-bottom: calc(-1 * var(--spacing__unit--1));
}

.inlineTextArea {
  width: 100%;
}

.saveButtons {
  display: flex;
  gap: var(--dig-spacing__micro__small);
  justify-content: flex-end;
  margin-top: var(--dig-spacing__micro__small);
  padding-top: var(--dig-spacing__micro__small);
  border-top: 1px solid var(--dig-color__border__subtle);
}

.stacksEditMode {
  transition:
    width 2s,
    height 2s,
    background-color 2s,
    rotate 2s;
}

.smallMarginTop {
  margin-top: var(--spacing__unit--1);
}

.isBlock {
  display: block;
}
