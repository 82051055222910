.MarkdownResponse table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-left: calc(var(--dig-spacing__micro__medium) * -1);
  margin-right: calc(var(--dig-spacing__micro__medium) * -1);
}

.MarkdownResponse table td,
.MarkdownResponse table th {
  vertical-align: top;
  text-align: start;
  padding: var(--dig-spacing__micro__medium);
  outline: none;
}

.MarkdownResponse table th {
  color: var(--dig-color__text__subtle);
  font-weight: 400;
}

.MarkdownResponse table td {
  border: 1px solid var(--dig-color__border__subtle--on-base);
}

.MarkdownResponse table td:first-child {
  border-left: 0;
}

.MarkdownResponse table td:last-child {
  border-right: 0;
}
