.AnswerCitationCardsGrid {
  display: grid;
  /* 2 columns */
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.AnswerCitationCardsGrid.singleRow {
  /* 4 columns */
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.AnswerCitationCardsTitle {
  display: block;
  margin-bottom: 16px;
}
