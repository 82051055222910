.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dig-color__background__subtle);
  border-radius: var(--dig-radius__medium);
  padding: var(--dig-spacing__micro__medium);
  box-shadow: 0px 4px 12px 0px var(--dig-color__shadow__elevated);
}

.hueContainer {
  position: relative;
  width: 100%;
  height: var(--dig-spacing__micro__small) !important;
  margin-top: var(--dig-spacing__micro__large);
  margin-bottom: var(--dig-spacing__micro__large);
}

.hueContainer > div {
  border-radius: var(--dig-spacing__micro__xsmall) !important;
}

.pointerRootDiv {
  width: var(--dig-spacing__micro__xlarge);
  height: var(--dig-spacing__micro__xlarge);
  border: solid 2px white;
  position: absolute;
  border-radius: var(--dig-radius__medium);
  box-shadow: var(--boxshadow__elevation2);
  transform: translate(-50%, -50%);
}

.saturation {
  border-radius: var(--dig-radius__medium) !important;
  aspect-ratio: 1;
  width: 100% !important;
}

.colorInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: var(--dig-spacing__micro__small);
}

.colorInputLabel {
  margin-right: var(--dig-spacing__micro__medium);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: var(--dig-spacing__micro__small);
  padding-top: var(--dig-spacing__micro__small);
}
