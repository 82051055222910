.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  padding-bottom: var(--dig-spacing__macro__small);
  max-width: 100%;
  flex-wrap: nowrap;
}

.skeletonItem {
  flex: 1;
  aspect-ratio: 5/4;
  border-radius: 4px;
}

.imageContainer {
  display: flex;
  flex: 1;
  border: 1px solid var(--dig-color__border__subtle);
  min-height: 133px;
}

.imageContainer.imageLoading {
  display: none;
}

.imageContainer.renderDelay {
  border: none;
}

.image {
  aspect-ratio: 5/4;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
