.ContextInputMessageWrapper {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  animation: bubbleAppear 0.4s ease forwards;
}

.ContextInputMessageWrapperDisabled {
  pointer-events: none;
}

@keyframes bubbleAppear {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ContextInputMessageIconContainer {
  border-radius: 50%;
  width: 24px;
  border: 1px solid var(--dig-color__border__subtle);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background: var(--dig-color__background__base);
}

.ContextInputMessageIcon {
  flex: 0 0 auto;
  color: var(--dig-color__text__subtle);
}

.ContextInputMessageWrapperContent {
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
  flex: 1 1 0;
  min-width: 0;
}

.ContextInputMessageInstruction {
  margin-left: var(--dig-spacing__micro__xsmall);
}

.ContextInputContentTypeMessageChip {
  margin-right: 8px;
  margin-top: 8px;
  border: 1px solid var(--dig-color__border__subtle);
  background: var(--dig-color__background__base);
}

.ContextInputContentTypeMessageChipSelected {
  background: var(--dig-color__primary-cta__base);
}

.ContextInputSourcesChipRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.ContextInputSourcesChipContainer {
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__large);
  background-color: var(--dig-color__background__base);
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__small)
    var(--dig-spacing__micro__medium) var(--dig-spacing__micro__large);
  display: flex;
  width: 272px;
  align-items: center;
  gap: 12px;
}

.ContextInputSourcesChip {
  display: inline-block;
  width: 100%;
}

.ContextInputSourcesActions {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ContextInputSourceActionChip {
  background: var(--dig-color__background__base);
  border: 1px solid var(--dig-color__border__subtle);
}
