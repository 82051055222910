.ContextInputSourcesChipRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.ContextInputSourcesChipContainer {
  border: 1px solid var(--dig-color__border__subtle);
  border-radius: var(--dig-radius__large);
  background-color: var(--dig-color__background__base);
  padding: var(--dig-spacing__micro__medium) var(--dig-spacing__micro__small)
    var(--dig-spacing__micro__medium) var(--dig-spacing__micro__large);
  display: flex;
  width: 272px;
  align-items: center;
  gap: 12px;
}

.ContextInputSourcesChip {
  display: inline-block;
  width: 100%;
}
