.container {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 120px;

  border-radius: var(--spacing__unit--1);
  background-color: var(--dig-color__background__base);
  width: 400px;
  box-shadow: var(--boxshadow__elevation2);

  padding: var(--spacing__unit--2);
  box-sizing: content-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing__unit--1);
}

.image {
  max-width: 400px;
}
