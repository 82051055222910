.sectionHeaderContainer {
  /**
  we set a 0px padding on the bottom since Dig's List Item comes with micro_small (8px) padding on top and bottom
  rather than overriding in the dig component it's safer to adjust in the header container
   */
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__micro__xlarge)
    0px var(--dig-spacing__micro__xlarge);
}

.sectionItemContainer {
  padding: 0px var(--dig-spacing__micro__small);
}

/* We nest Dash's link component in Dig's List.Item and need to ensure it takes the whole width for hover state */
.digListItem > * {
  flex: 1;
}
