.profileBodySection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  gap: 24px;
  align-self: stretch;
}

.profileNameCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: center;
}

.profileNameHeader {
  display: flex;
  align-items: flex-end;
  gap: 6px;
}

.profileNameAvatar {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.teamMembersContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

@media only screen and (max-width: 860px) {
  .profileNameHeader {
    flex-direction: column;
  }

  .teamMembersContainer {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}
