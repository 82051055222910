.inviteModalFooter {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: var(--dig-spacing__micro__small);
  height: 80px;
}

.inviteModalLoadingSpinnerContainer {
  /**
    * Loaded modal content does not have fixed height to match. We just choose a reasonable 
    * fixed height for the loading spinner container
    */
  height: 250px;
}
