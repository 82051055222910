/* No choice but to override the DIG style here. */
.fullWidthWrapper :global(.dig-TextInputContainer):has(input:focus),
.fullWidthWrapper :global(.dig-Link):focus,
.fullWidthWrapper :global(.dig-IconButton):focus {
  box-shadow: none;
}

/* No choice but to override the DIG style here. */
.fullWidthWrapper :global(.dig-TextInputContainer):has(input:focus-visible) {
  box-shadow: none;
}

.fullWidthWrapper {
  position: absolute;
  /* Half the height of the row, plus 1px for border */
  top: calc(-1 * (var(--dig-spacing__micro__xlarge) / 2) + 1px);
  left: 0;
  width: 100%;
}

.fullWidthWrapperStackChat {
  padding-left: 0;
}

.box {
  border-radius: var(--dig-radius__large);
  border: 1px solid var(--dig-color__border__subtle);
  background-color: var(--dig-color__background__base);
  box-shadow: var(--dig-utilities__boxshadow__raised);
  overflow: hidden;
  padding: 0;
}

.searchInputContainer {
  padding: 8px var(--spacing__unit--2);
  display: flex;
  flex-direction: row;
  gap: var(--spacing__unit--1);
  align-items: center;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
}

.searchTextInputWrapper {
  padding-left: 0px;
  padding-right: 0px;
}

.searchIcon {
  margin-right: var(--spacing__unit--1_5);
}
