.editorTextBold {
  font-weight: 500;
}

.editorTextItalic {
  font-style: italic;
}

.editorTextStrikethrough {
  text-decoration: line-through;
}

.editorTextUnderline {
  text-decoration: underline;
}

.editorTextUnderlineStrikethrough {
  text-decoration: underline line-through;
}
