.modal {
  padding: var(--dig-spacing__dimension__60) 0px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.image {
  margin-bottom: var(--dig-spacing__macro__small);
}

.subtitleContainer {
  margin-bottom: var(--dig-spacing__macro__large);
}

.buttonContainer {
  width: 320px;
  margin-bottom: var(--dig-spacing__macro__small);
}
