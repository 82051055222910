.AssistActions {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
  align-self: flex-end;
}

.ActionChip {
  background: var(--dig-color__background__base);
  border: 1px solid var(--dig-color__border__subtle);
}

.ActionChipContainer {
  display: flex;
  gap: 2px;
  align-items: center;
}
